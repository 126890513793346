import { useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import React from "react";
import * as Realm from "realm-web";
import { useLocation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import LoadingDialog from "../../../components/LoadingDialog";
import {
  get_mongodb_values,
  getChargebeeSubscriptionIDByEmail,
  ChargebeeUserManageOnboarding,
} from "../../../services/users_services";
import SweetAlert from "react-bootstrap-sweetalert";
import { useHistory } from "react-router";
function AppManage() {
  const history = useHistory();
  const location = useLocation();
  const { user, isAuthenticated, loginWithRedirect } = useAuth0();
  const [loadDialogOpen, setloadDialogOpen] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [apptitle, setapptitle] = useState("Contact Sync");
  const [mongodbvalues, setMongodbvalues] = useState({});
  const queryParameters = new URLSearchParams(window.location.search);
  const redirectUrl = window.location.origin;
  const redirectManagerURLmap = new Map()
    .set(redirectUrl + "/connection/commandpost/manage", "Command Post")
    .set(redirectUrl + "/connection/quickbooks/manage", "Quickbooks")
    .set(redirectUrl + "/connection/procore/manage", "Procore")
    .set(redirectUrl + "/connection/master/manage", "Masters");

  // Get the API key from the local environment
  const apiKey = process.env.REACT_APP_APIKEY;
  if (!apiKey) {
    throw new Error("Could  not find a Realm Server API Key.");
  }
  // Create an api key credential
  const credentials = Realm.Credentials.apiKey(apiKey);
  const localapp = new Realm.App(process.env.REACT_APP_REALM_APP_ID);

  useEffect(() => {
    setapptitle(redirectManagerURLmap.get(window.location.href));
    try {
      localapp.logIn(credentials).then(() => {
        get_mongodb_values(localapp.currentUser, localapp.currentUser.id).then(
          (response) => {
            if (response !== undefined) {
              setMongodbvalues(response);
              if (
                localapp.currentUser !== null &&
                localapp.currentUser !== undefined
              ) {
                localapp.removeUser(localapp.currentUser);
              }
            } else {
              if (
                localapp.currentUser !== null &&
                localapp.currentUser !== undefined
              ) {
                localapp.removeUser(localapp.currentUser);
              }
            }
          }
        );
      });
      if (localapp.currentUser !== null && localapp.currentUser !== undefined) {
        localapp.removeUser(localapp.currentUser);
      }
    } catch (err) {
      console.log("err : " + err);
      if (localapp.currentUser !== null && localapp.currentUser !== undefined) {
        localapp.removeUser(localapp.currentUser);
      }
    }

    const type_value = queryParameters.get("type") || "";
    console.log("type_value", type_value);
    if (type_value === "1" && isAuthenticated) {
      ChargebeeUserManageOnboardingByEmail(user.email);
    }
    else {
      RedirectPage();
    }
  }, []);

  async function RedirectPage(e) {
    //e.preventDefault();
    setloadDialogOpen(true);
    try {
      if (!isAuthenticated) {
        let paramsinfo = { type: "appconnection", pathName: location.pathname };
        //returnTo: window.location.href
        const appStateJSON = encodeURIComponent(JSON.stringify(paramsinfo));
        const options = {
          appState: { appStateJSON: appStateJSON },
          redirectUri: window.location.origin,
        };
        await loginWithRedirect(options);
        // const url = new URL(mongodbvalues.commandpost_billing_redirect_url);
        // window.location.replace(url);
      } else {
        await ChargebeeUserManageOnboardingByEmail(user.email);
        //console.log("user.email ", user.email);
      }
    } catch (err) {
      console.log("err : " + err);
      setloadDialogOpen(false);
      if (localapp.currentUser !== null && localapp.currentUser !== undefined) {
        localapp.removeUser(localapp.currentUser);
      }
    }
  }
  async function RedirectAppsPage() {
    // history.push({
    //     pathname: "/apps",
    //     state: { pathName: location.pathname },
    // });
    window.location.href = window.location.origin + "/";
  }
  async function ChargebeeUserManageOnboardingByEmail(emailaddress) {
    setloadDialogOpen(true);
    try {
      localapp.logIn(credentials).then(() => {
        ChargebeeUserManageOnboarding(
          emailaddress,
          location.pathname || "",
          localapp.currentUser,
          localapp.currentUser.id
        ).then((response) => {
          if (response !== undefined) {
            //  setIsSuccess(true);
            //window.location.href = window.location.origin + "/apps"
            if (
              localapp.currentUser !== null &&
              localapp.currentUser !== undefined
            ) {
              localapp.removeUser(localapp.currentUser);
            }
            RedirectAppsPage();
          } else {
            if (
              localapp.currentUser !== null &&
              localapp.currentUser !== undefined
            ) {
              localapp.removeUser(localapp.currentUser);
            }
          }
        });
      });
      if (localapp.currentUser !== null && localapp.currentUser !== undefined) {
        localapp.removeUser(localapp.currentUser);
        setloadDialogOpen(false);
      }
    } catch (err) {
      console.log("err : " + err);
      setloadDialogOpen(false);
    }
  }

  const handleSweetAlert = () => {};
  return (
    <div className="mb-2">
      <h3 className="m-2" align="center">
        Welcome to {apptitle} app manage
      </h3>
      <div className="d-flex justify-content-center align-self-start  welcomehintwindow">
        {/* <ui>
                <li>Sample hint1</li>
                <li>Sample hint2</li>
                <li>Sample hint3</li>
                <li>Sample hint4</li>
            </ui> */}
      </div>
      <div className="d-flex-inline align-items-center">
        <div class="d-flex justify-content-center mt-4">
          {/* {queryParameters.get("type") !== "1" && (
            <div>
              <button
                className="btn btn-primary"
                onClick={(e) => {
                  RedirectPage(e);
                }}
              >
                Next
              </button>
            </div>
          )} */}
        </div>
      </div>
      <div></div>
      <LoadingDialog
        isOpen={loadDialogOpen}
        onClose={() => setloadDialogOpen(false)}
      />
      {isSuccess && (
        <SweetAlert
          success
          style={{ color: "black" }}
          title="App has been onboarded successfully."
          onConfirm={handleSweetAlert}
        ></SweetAlert>
      )}
    </div>
  );
}
export default AppManage;
