import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import React from "react";
import { Container } from "react-bootstrap";
import MongoContext from "./MongoContext";
import { useEffect, useState } from "react";
import * as Realm from "realm-web";
import ContactList from "./pages/contact/contactlist";
import ContactProfile from "./pages/contact/ContactProfile";
import Signin from "./pages/authenticate/Signin";
import SignInByInvitation from "./pages/authenticate/signinbyinvitation";
import Signup from "./pages/authenticate/Signup";
import SignupByInvitation from "./pages/authenticate/SignupByInvitation";
import Listapps from "./pages/apps/listapps";
import AppDashboard from "./pages/apps/AppDashboard";
import Addapps from "./pages/apps/Addapps";
import Editapps from "./pages/apps/editapps";
import Detailapps from "./pages/apps/detailapps";
import VerificationMsg from "./pages/contact/verificationmsg";
import Pendingverificationlist from "./pages/contact/pendingverificationlist";
import Contactverification from "./pages/contact/contactverification";
import AdminVerificationdetail from "./pages/contact/adminverificationdetail";
import QBConnect from "./pages/quickbooks/QBConnect";
import ProcoreProjects from "./pages/procore/ProcoreProjects";
import ProcoreContacts from "./pages/procore/ProcoreContacts";
import ProcoreImportWizard from "./pages/procore/ProcoreImportWizard";
import ProcoreImportStatus from "./pages/procore/ProcoreImportStatus";
import QuickbooksImportWizard from "./pages/quickbooks/QuickbooksImportWizard";
import QuickbooksImportStatus from "./pages/quickbooks/QuickbooksImportStatus";
import EntraIDImportStatus from "./pages/entraid/EntraIDImportStatus";
import EntraIDImportWizard from "./pages/entraid/EntraIDImportWizard";
import CommandpostImportWizard from "./pages/commandpost/CommandpostImportWizard";
import CommandpostImportStatus from "./pages/commandpost/CommandpostImportStatus";
import EditProject from "./pages/procore/EditProject";
import EditContact from "./pages/contact/EditContact";
import DetailContact from "./pages/contact/DetailContact";
import AddContact from "./pages/contact/AddContact";
import ChangePassword from "./pages/authenticate/changepassword";
import ForgotPassword from "./pages/authenticate/forgotpassword";
import ResetPassword from "./pages/authenticate/resetpassword";
import PendingCreationList from "./pages/contact/pendingCreatonList";
import PendingUpdateList from "./pages/contact/pendingUpdateList";
import ProcoreConnect from "./pages/procore/ProcoreConnect";
import LinkedInConnect from "./pages/linkedin/LinkedInConnect";
import Signupwithlob from "./pages/authenticate/Signupwithlob";
import LinkedInProfile from "./pages/linkedin/LinkedInProfile";
import CommandPostSignup from "./pages/commandpost/commandpostsignup";
import Generalsettings from "./pages/settings/generalsettings";
import AppManage from "./pages/apps/appconnection/AppManage";
import AppSetup from "./pages/apps/appconnection/AppSetup";
import AppSetupExisting from "./pages/apps/appconnection/AppSetupExisting";

import { useRealmApp } from "./providers/RealmApp";

import {
  getuserbyauthproviderid,
  getChargebeeSubscriptionStatus,
} from "./services/users_services";

// Importing toastify module
import { toast } from "react-toastify";
//AUth0*/
import NavBar from "./components/NavBar";
import Footer from "./components/footer";
import Loading from "./components/Loading";
import { useAuth0 } from "@auth0/auth0-react";
import history from "./utils/history";
import SweetAlert from "react-bootstrap-sweetalert";
import LoadingDialog from "./components/LoadingDialog";
import { useLocation } from "react-router-dom";
// fontawesome
import initFontAwesome from "./utils/initFontAwesome";
initFontAwesome();

toast.configure();
const App = (props) => {
  /* Auth 0 */
  const { isLoading, error, isAuthenticated, user, logout } = useAuth0();
  const [loadDialogOpen, setloadDialogOpen] = useState(false);
  const [client, setClient] = useState(null);
  const [mongodbuser, setmongodbUser] = useState(null);
  const [app, setApp] = useState(useRealmApp());
  const [userInfo, setUserInfo] = useState(false);
  const [authClient, setoauthClient] = useState(null);
  const ctechapp = useRealmApp();
  const location = useLocation();
  const [isFailure, setisFailure] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  async function authmongdbuser() {
    try {
      // await ctechapp.logOutAllUsers();
      const userdata = {
        userauthid: user.sub,
      };
      let newUser1 = null;
      try {
        newUser1 = await ctechapp.logIn(
          Realm.Credentials.function(userdata)
        );
      }
      catch (ex) {
        console.log("error ", ex);
      }

      if (newUser1 !== null) {
        getuserbyauthproviderid(user.sub, newUser1).then((response) => {
          // console.log(JSON.stringify(response));
          let user_info = JSON.parse(response);
          if (response !== undefined || user_info.length !== 0) {
            if (newUser1 !== undefined) {
              setmongodbUser(newUser1);
              setUserInfo(user_info);
              PageRedirector();
            }
          }
          //window.location.href = "/contactprofile";
        });
      }
      else {
        PageRedirector();
      }
    } catch (err) {
      console.error("Failed to log in 1 ", err.message);
      setisFailure(true);
    }
  }
  function PageRedirector() {
    const queryparams = new URLSearchParams(location.search);

    let appStateJSON = queryparams.get("appStateJSON");
    console.log("appStateJSON", appStateJSON);
    if (appStateJSON) {
      const _appStateJSON = JSON.parse(appStateJSON);
      const _pathName = _appStateJSON.pathName || "/dashboard";
      if (_pathName && _pathName.includes("/manage")) {
        window.location.href = window.location.origin + _pathName + "?type=1";
      } else {
        window.location.href = window.location.origin + "/dashboard";
      }
    } else {
      if (window.location.pathname === "/")
        window.location.href = window.location.origin + "/dashboard";
    }
  }
  function ChargebeeSubscriptionCheck() {
    let status = false;
    try {
      getChargebeeSubscriptionStatus(user.email, ctechapp.currentUser).then(
        (response) => {
          // console.log(JSON.stringify(response));
          if (response.statusCode !== 200) {
            setIsError(true);
            setErrorMessage(response.message);
          } else {
            status = true;
            // PageRedirector();
            if (
              ctechapp.currentUser !== null &&
              window.location.pathname === "/"
            ) {
              window.location.href = window.location.origin + "/dashboard";
            }
          }
        }
      );
    } catch (err) {
      console.error("Failed to log in 1 ", err);
      setIsError(true);
      setErrorMessage(err);
      status = false;
    }
    return status;
  }
  useEffect(() => {
    window.addEventListener("message", handleCallbackPostMessage);
    //const queryparams = new URLSearchParams(location.search);

    //let appStateJSON = queryparams.get("appStateJSON");
    //console.log("appStateJSON", appStateJSON);
    if (isAuthenticated) {
      if (ctechapp.users.length === 0) authmongdbuser();
      if (ctechapp.users.length !== 0) {
        const isSubscriptionValid = ChargebeeSubscriptionCheck();
        PageRedirector();
        // if (isSubscriptionValid) {
        //   if (
        //     ctechapp.currentUser !== null &&
        //     window.location.pathname === "/"
        //   ) {
        //     window.location.href = window.location.origin + "/dashboard";
        //   }
        // }
      }
    }
  }, [user, isAuthenticated]);

  fetch("/api")
    .then((res) => res.json())
    .then((data) => { });
  async function setserviceaccount() {
    // Get the API key from the local environment
    const apiKey = process.env.REACT_APP_APIKEY;
    if (!apiKey) {
      throw new Error("Could  not find a Realm Server API Key.");
    }
    // Create an api key credential
    const credentials = Realm.Credentials.apiKey(apiKey);
    try {
      const serviceUser = await app.logIn(credentials);

      setmongodbUser(serviceUser);
    } catch (err) { }
  }

  function renderComponent(Component, type, additionalProps = {}) {
    if (
      isAuthenticated ||
      window.location.pathname === "/" ||
      window.location.pathname.includes("commandpostsignup") ||
      window.location.pathname.includes("Signup") ||
      window.location.pathname === `/Signup/0/0` ||
      window.location.pathname.includes("signupbyinvitation") ||
      window.location.pathname === `/signupbyinvitation/0/0` ||
      window.location.pathname === "/signupwithlob" ||
      window.location.pathname.includes("LinkedInProfile") ||
      window.location.pathname === "/forgotpassword" ||
      window.location.pathname.includes("resetpassword") ||
      window.location.pathname === "/resetpassword" ||
      window.location.pathname.includes("contactverification") ||
      window.location.pathname === "/contactverification" ||
      window.location.pathname.includes("signinbyinvitation") ||
      window.location.pathname === "/signinbyinvitation/0/0" ||
      window.location.pathname.includes("verificationmsg") ||
      window.location.pathname === "/verificationmsg/0/0" ||
      window.location.pathname.includes("/connection/procore/manage") ||
      window.location.pathname.includes("/connection/quickbooks/manage") ||
      window.location.pathname.includes("/connection/commandpost/manage") ||
      window.location.pathname.includes("/connection/procore/setup") ||
      window.location.pathname.includes("/connection/quickbooks/setup") ||
      window.location.pathname.includes("/connection/commandpost/setup") ||
      window.location.pathname.includes("/connection/master/setup") ||
      window.location.pathname.includes("/connection/master/procore/setup") ||
      window.location.pathname.includes("/connection/master/procore/signup") ||
      window.location.pathname.includes(
        "/connection/master/quickbooks/signup"
      ) ||
      window.location.pathname.includes(
        "/connection/master/quickbooks/setup"
      ) ||
      window.location.pathname.includes("/connection/procore/setupexisting") ||
      window.location.pathname.includes(
        "/connection/quickbooks/setupexisting"
      ) ||
      window.location.pathname.includes("/connection/commandpost/setupexisting")
    ) {
      return (
        <MongoContext.Consumer>
          {(mongoContext) => (
            <Component mongoContext={mongoContext} {...additionalProps} />
          )}
        </MongoContext.Consumer>
      );
    }
  }

  /*LinkedinProfile*/
  const handleCallbackPostMessage = (event) => {
    if (event.data.type === "profile") {
      updateLinkedinProfile(event.data.profile);
    }
    if (event.data.type === "qbtoken") {
      updateQBToken(event.data.qbtoken);
    }
    if (event.data.type === "procoretoken") {
      updateProcoreToken(event.data.procoretoken);
    }
  };

  const updateLinkedinProfile = (profile) => {
    window.location.href = "/LinkedInProfile/" + profile.ids;
  };

  /*qbtoken*/
  const updateQBToken = (qbtoken) => {
    if (
      qbtoken.callbackpage === "editapps" ||
      qbtoken.callbackpage === "addapps"
    ) {
      window.location.href = "/editapps/" + qbtoken.keyid;
    } else {
      window.location.href = "/QBConnect/" + qbtoken.companyid;
    }
  };

  /*procoretoken*/
  const updateProcoreToken = (procoretoken) => {
    if (
      procoretoken.callbackpage === "editapps" ||
      procoretoken.callbackpage === "addapps"
    ) {
      window.location.href = "/editapps/" + procoretoken.keyid;
    } else {
      window.location.href = "/procoreConnect/" + procoretoken.companyid;
    }
  };
  /*Auth0 */
  if (error) {
    return;
    <SweetAlert
      warning
      style={{ color: "black" }}
      title="User Login failed."
      onConfirm={handleFailureSweetAlert}
    >
      {error.message}
    </SweetAlert>;
  }

  if (isLoading) {
    return (
      <LoadingDialog
        isOpen={isLoading}
        onClose={() => setloadDialogOpen(false)}
      />
    );
  }

  const handleFailureSweetAlert = () => {
    setisFailure(false);
    let redirecturl = window.location.origin;
    // if (location.pathname.includes("/manage")) {
    //   redirecturl = window.location.origin + location.pathname;
    // }

    logout({
      returnTo: redirecturl,
    });
  };

  const handleErrorSweetAlert = () => {
    setIsError(false);
    logout({
      returnTo: window.location.origin,
    });
  };
  const navigateViews = () => {
    if (!isAuthenticated) {
      return (
        <Router>
          <NavBar />
          <MongoContext.Provider
            value={{
              app,
              client,
              mongodbuser,
              authClient,
              setClient,
              setmongodbUser,
              setApp,
              setoauthClient,
            }}
          >
            <Container className="flex-grow-1">
              <Switch>
                <Route
                  path="/signup/:ids/:emailsignup"
                  render={() => renderComponent(Signup)}
                />
                <Route
                  path="/signupbyinvitation/:ids/:emailsignup"
                  render={() => renderComponent(SignupByInvitation)}
                />
                <Route
                  path="/signinbyinvitation/:ids/:emailsignup"
                  render={() => renderComponent(SignInByInvitation)}
                />
                <Route
                  path="/contactverification/:token"
                  render={() => renderComponent(Contactverification)}
                />
                <Route
                  path="/verificationmsg:requestcontact/:affname"
                  render={() => renderComponent(VerificationMsg)}
                />
                <Route
                  path="/signupwithlob"
                  render={() => renderComponent(Signupwithlob)}
                />
                <Route
                  path="/LinkedInConnect"
                  render={() => renderComponent(LinkedInConnect)}
                />

                <Route
                  path="/LinkedInProfile/:ids"
                  render={() => renderComponent(LinkedInProfile)}
                />
                <Route
                  path="/commandpostsignup"
                  render={() => renderComponent(CommandPostSignup)}
                />
                <Route
                  path="/verificationmsg/:requestcontact/:affname"
                  render={() => renderComponent(VerificationMsg)}
                />
                <Route
                  path="/connection/procore/setup"
                  render={() => renderComponent(AppSetup)}
                />
                <Route
                  path="/connection/quickbooks/setup"
                  render={() => renderComponent(AppSetup)}
                />
                <Route
                  path="/connection/master/procore/setup"
                  render={() => renderComponent(AppSetup)}
                />

                <Route
                  path="/connection/master/procore/signup"
                  render={() => renderComponent(Signup)}
                />
                <Route
                  path="/connection/master/quickbooks/setup"
                  render={() => renderComponent(AppSetup)}
                />
                <Route
                  path="/connection/master/quickbooks/signup"
                  render={() => renderComponent(Signup)}
                />
                <Route
                  path="/connection/commandpost/setup"
                  render={() => renderComponent(AppSetup)}
                />
                <Route
                  path="/connection/master/setup"
                  render={() => renderComponent(AppSetup)}
                />
                <Route
                  path="/connection/procore/setupexisting"
                  render={() => renderComponent(AppSetupExisting)}
                />
                <Route
                  path="/connection/quickbooks/setupexisting"
                  render={() => renderComponent(AppSetupExisting)}
                />
                <Route
                  path="/connection/commandpost/setupexisting"
                  render={() => renderComponent(AppSetupExisting)}
                />
                <Route
                  path="/connection/procore/manage"
                  render={() => renderComponent(AppManage)}
                />
                <Route
                  path="/connection/quickbooks/manage"
                  render={() => renderComponent(AppManage)}
                />
                <Route
                  path="/connection/commandpost/manage"
                  render={() => renderComponent(AppManage)}
                />
              </Switch>
            </Container>
          </MongoContext.Provider>
          <Footer />
        </Router>
      );
    } else {
      if (isAuthenticated)
        return (
          <Router>
            <NavBar />

            <MongoContext.Provider
              value={{
                app,
                client,
                mongodbuser,
                authClient,
                setClient,
                setmongodbUser,
                setApp,
                setoauthClient,
              }}
            >
              <Container className="flex-grow-1">
                <Switch>
                  <Route
                    path="/contactprofile"
                    render={() => renderComponent(ContactProfile)}
                  />
                  <Route
                    path="/commandpostsignup"
                    render={() => renderComponent(CommandPostSignup)}
                  />
                  <Route
                    path="/signup/:ids/:emailsignup"
                    render={() => renderComponent(Signup)}
                  />
                  <Route
                    path="/signupbyinvitation/:ids/:emailsignup"
                    render={() => renderComponent(SignupByInvitation)}
                  />
                  <Route
                    path="/addapps/:ids"
                    render={() => renderComponent(Addapps)}
                  />
                  <Route
                    path="/editapps/:ids"
                    render={() => renderComponent(Editapps)}
                  />
                  <Route
                    path="/detailapps/:ids"
                    render={() => renderComponent(Detailapps)}
                  />
                  <Route
                    path="/pendingverificationlist"
                    render={() => renderComponent(Pendingverificationlist)}
                  />
                  <Route
                    path="/adminverificationdetail/:ids"
                    render={() => renderComponent(AdminVerificationdetail)}
                  />
                  <Route
                    path="/contactverification/:token"
                    render={() => renderComponent(Contactverification)}
                  />
                  <Route
                    path="/verificationmsg/:requestcontact/:affname"
                    render={() => renderComponent(VerificationMsg)}
                  />
                  <Route
                    path="/contactlist"
                    render={() => renderComponent(ContactList)}
                  />
                  <Route
                    path="/changepassword"
                    render={() => renderComponent(ChangePassword)}
                  />
                  <Route
                    path="/forgotpassword"
                    render={() => renderComponent(ForgotPassword)}
                  />
                  <Route
                    path="/resetpassword/:token"
                    render={() => renderComponent(ResetPassword)}
                  />
                  <Route
                    path="/QBConnect/:companyid"
                    render={() => renderComponent(QBConnect)}
                  />
                  <Route
                    path="/ProcoreConnect/:companyid"
                    render={() => renderComponent(ProcoreConnect)}
                  />

                  <Route
                    path="/apps"
                    render={() => renderComponent(Listapps)}
                  />
                  <Route
                    path="/dashboard"
                    render={() => renderComponent(AppDashboard)}
                  />
                  <Route
                    path="/procoreprojects/:companyid"
                    render={() => renderComponent(ProcoreProjects)}
                  />
                  <Route
                    path="/procorecontacts"
                    render={() => renderComponent(ProcoreContacts)}
                  />
                  <Route
                    path="/procoreimportwiz"
                    render={() => renderComponent(ProcoreImportWizard)}
                  />
                  <Route
                    path="/procoreimportstatus"
                    render={() => renderComponent(ProcoreImportStatus)}
                  />
                  <Route
                    path="/quickbooksimportwiz"
                    render={() => renderComponent(QuickbooksImportWizard)}
                  />
                  <Route
                    path="/quickbooksimportstatus"
                    render={() => renderComponent(QuickbooksImportStatus)}
                  />
                  <Route
                    path="/entraidimportwiz"
                    render={() => renderComponent(EntraIDImportWizard)}
                  />
                  <Route
                    path="/entraidimportstatus"
                    render={() => renderComponent(EntraIDImportStatus)}
                  />
                  <Route
                    path="/commandpostimportwiz"
                    render={() => renderComponent(CommandpostImportWizard)}
                  />

                  <Route
                    path="/commandpostimportstatus"
                    render={() => renderComponent(CommandpostImportStatus)}
                  />
                  <Route
                    path="/editproject/:projectid/:companyid"
                    render={() => renderComponent(EditProject)}
                  />
                  <Route
                    path="/editcontact/:userid"
                    render={() => renderComponent(EditContact)}
                  />
                  <Route
                    path="/detailcontact"
                    render={() => renderComponent(DetailContact)}
                  />
                  <Route
                    path="/addcontact"
                    render={() => renderComponent(AddContact)}
                  />
                  <Route
                    path="/pendingcreationlist"
                    render={() => renderComponent(PendingCreationList)}
                  />

                  <Route
                    path="/pendingupdatelist"
                    render={() => renderComponent(PendingUpdateList)}
                  />
                  <Route
                    path="/signupwithlob"
                    render={() => renderComponent(Signupwithlob)}
                  />
                  <Route
                    path="/generalsettings"
                    render={() => renderComponent(Generalsettings)}
                  />
                  <Route
                    path="/connection/procore/setup"
                    render={() => renderComponent(AppSetup)}
                  />
                  <Route
                    path="/connection/quickbooks/setup"
                    render={() => renderComponent(AppSetup)}
                  />
                  <Route
                    path="/connection/commandpost/setup"
                    render={() => renderComponent(AppSetup)}
                  />
                  <Route
                    path="/connection/procore/setupexisting"
                    render={() => renderComponent(AppSetupExisting)}
                  />
                  <Route
                    path="/connection/quickbooks/setupexisting"
                    render={() => renderComponent(AppSetupExisting)}
                  />
                  <Route
                    path="/connection/commandpost/setupexisting"
                    render={() => renderComponent(AppSetupExisting)}
                  />
                  <Route
                    path="/connection/procore/manage"
                    render={() => renderComponent(AppManage)}
                  />
                  <Route
                    path="/connection/quickbooks/manage"
                    render={() => renderComponent(AppManage)}
                  />
                  <Route
                    path="/connection/commandpost/manage"
                    render={() => renderComponent(AppManage)}
                  />
                  <Route path="/" render={() => renderComponent(Signin)} />
                </Switch>
              </Container>
            </MongoContext.Provider>
            <Footer />
          </Router>
        );
    }
  };
  return (
    <div id="app" className="d-flex flex-column h-100">
      {navigateViews()}
      <div>
        {isFailure && (
          <SweetAlert
            warning
            style={{ color: "black" }}
            title="User Login failed."
            onConfirm={handleFailureSweetAlert}
          ></SweetAlert>
        )}
      </div>
      <div>
        {isError && (
          <SweetAlert
            warning
            style={{ color: "black" }}
            title={errorMessage}
            onConfirm={handleErrorSweetAlert}
          ></SweetAlert>
        )}
      </div>
    </div>
  );
};

export default App;
